import { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './layer/footer/Footer';

import Header from './layer/header/Header';
import LoginPage from './page/LoginPage';
import MainPage from './page/MainPage';

import AuthContext from './store/auth-context';

import UserProfile from './Components/Profile/UserProfile';

function App() {

  const authCtx = useContext(AuthContext);

  return (
    <div className='appdiv'>

        <Header></Header>

        <Switch>
          <Route path='/' exact >
            <MainPage />
          </Route>
          
          {!authCtx.isLoggedIn && (
          <Route path='/auth'>
            <LoginPage />
          </Route>
            )}


          <Route path='/profile'>
          {authCtx.isLoggedIn && <UserProfile />}
          {!authCtx.isLoggedIn && <Redirect to='/auth' />}
          </Route>


          <Route path='*'>
            <Redirect to='/' />
          </Route>



       
        </Switch>



      

        <Footer></Footer>

      

    </div>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;

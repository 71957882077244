import { useContext } from 'react';

import AuthContext from '../../store/auth-context';

import './Menu.css';

function Menu() {

  const authCtx = useContext(AuthContext);

  const isLoggedIn = authCtx.isLoggedIn;

  let arg1;
  let arg2;
  let arg3;

  //let apturl;
  let carurl;

  if (isLoggedIn)
  {
  arg1 = localStorage.getItem('user_id');
  arg2 = localStorage.getItem('token');
  arg3 = localStorage.getItem('expirationTime');
  //apturl = 'http://apartments.anotherecommerce.com';
  //apturl = 'http://apartments.anotherecommerce.com/urlnotfound?arg1='+ arg1 + '&arg2=' + arg2 + '&arg3='+ arg3+ '&arg4=zaaALoGib89p5tj3kzAiH18Ol';

  carurl = 'http://cars.anotherecommerce.com/urlnotfound?arg1='+ arg1 + '&arg2=' + arg2 + '&arg3='+ arg3+ '&arg4=zaaALoGib89p5tj3kzAiH18Ol';

  }



  return (
    <div>
{
  isLoggedIn &&

  <div className='displayPageLikeMenu'>


        <div className='displaySingleOption'>
          <p>
            Apartments for rent (Coming Soon)
          </p>
          <img width={'30%'} src='https://anotherecommerce.com/apartments.png'></img>
        </div>


        <div className='displaySingleOption'>
            <p> 
              <a href={carurl}>Cars for sales</a> 
            </p>
            <img width={'40%'} src='https://anotherecommerce.com/cars.png'></img>

            
        </div>


        <div className='displaySingleOption'>
          <p>Jobs to fill (Coming Soon)</p>
          <img width={'30%'} src='https://anotherecommerce.com/jobs.png'></img>
        </div>

      </div>



}


{
  !isLoggedIn &&

      <div className='displayPageLikeMenu'>


        <div className='displaySingleOption'>
          <p>
            Apartments for rent (Coming Soon)
          </p>
          <img width={'30%'} src='https://anotherecommerce.com/apartments.png'></img>
        </div>


        <div className='displaySingleOption'>
            <p> 
              <a href='http://cars.anotherecommerce.com'>Cars for sales</a> 
            </p>
            <img width={'40%'} src='https://anotherecommerce.com/cars.png'></img>
            
        </div>


        <div className='displaySingleOption'>
          <div className='innerwrapper'>
            <p>Jobs to fill (Coming Soon)</p>
            <img width={'30%'} src='https://anotherecommerce.com/jobs.png'></img>
          </div>
        </div>

      </div>
}

    </div>


  );
}

export default Menu;

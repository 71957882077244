import React from 'react';
import MainNavigation from '../../Components/Layout/MainNavigation';
import './Header.css';

function Header() {
    return (
      <div className='myheader'>
        

        <MainNavigation></MainNavigation>
  
      </div>
      );
    }

export default Header;
import React from 'react';
import Menu from '../layer/Menu/Menu';

function MainPage() {
  return (

    <div>
      <Menu></Menu>
      
    </div>

    );

}

export default MainPage;
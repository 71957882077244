import React from 'react';
import AuthForm from '../Components/Auth/AuthForm';

function LoginPage() {
  return (

    <div>
      <AuthForm></AuthForm>
    </div>

    );

}

export default LoginPage;
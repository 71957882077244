// import ProfileForm from './ProfileForm';
import classes from './UserProfile.module.css';


// add this in the change password section
// <ProfileForm />


const UserProfile = () => {
  return (
    <section className={classes.profile}>
      <h1>Your User Profile</h1>
    </section>
  );
};

export default UserProfile;

import React from 'react';
import './Footer.css'

function Footer() {
    return (
      <div className='myfooter'>
        <div className='footer'>
        <h5>Powered by AWS</h5>
        </div>
      </div>
      );
    }

export default Footer;